import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getContrastRatio } from '@mui/system/colorManipulator';
import classNames from 'clsx';
import Adsense from 'components/Adsense/Adsense';
import Container from 'components/Container/Container';
import MiddotDivider from 'components/MiddotDivider/MiddotDivider';
import PublicationAbout from 'components/Publications/About';
import PublicationGrid from 'components/Publications/Grid';
import PublicationMixed from 'components/Publications/Mixed';
import PlaceholderLoading from 'components/Publications/PlaceholderLoading';
import PublicationStream from 'components/Publications/Stream';
import config from 'config';
import NotFound from 'containers/NotFound/Loadable';
import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import VisibilitySensor from 'components/VisibilitySensor/VisibilitySensor';
import { getPublication } from 'redux/modules/publications';
import getImageLink from 'utils/getImageLink';
import { getLinkPublication as getLink } from 'utils/getLink';
import { useIntl } from 'react-intl';

const { domain } = config;

const getImage = (image: any, version: any) => {
  if (image && image.md5) return getImageLink(image.md5, version, image.format);
  return false;
};

const useStyles = makeStyles(
  {
    noStoriesContainer: {
      marginTop: 180,
      marginBottom: 180,
    },
    containerGrid: {
      marginTop: 18,
      marginBottom: 30,
    },
    containerContentOverImg: {},
    '@media (max-width: 767px)': {
      containerContentOverImg: {
        marginLeft: -28,
        marginRight: -28,
      },
    },
    containerPlaceholder: {
      width: '100%',
      minHeight: 1,
    },
    sectionTitle: {
      lineHeight: 1.2,
      color: 'rgba(0,0,0,.68)',
      fontSize: 20,
      fontWeight: 400,
      borderBottom: '1px solid rgba(0,0,0,.05)',
      paddingBottom: 8,
    },
    sectiontitlePromo: {
      marginTop: 35,
      marginBottom: -20,
    },
    promoContainer: {
      textAlign: 'center',
      paddingTop: 35,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 30,
      marginBottom: 15,
    },
    promoImageContainer: {},
    promoTextContainer: {
      maxWidth: 600,
      width: '100%',
    },
    promoTitle: {
      fontSize: 30,
      color: 'rgba(0,0,0,.54)',
      letterSpacing: 0,
      lineHeight: 1.3,
      fontWeight: 400,
    },
    adsenseInnerSections: {
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 20,
      overflow: 'hidden',
    },
    containerFooter: {
      borderTop: '1px solid rgba(0,0,0,.15)',
      paddingBottom: 30,
      paddingTop: 10,
      marginTop: 10,
    },
    linkSet: {
      marginTop: 10,
      textAlign: 'right',
      '@media (max-width: 767px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    noPadding: {
      padding: 0,
    },
    dotHidden: {
      display: 'inline-block',
      '@media (max-width: 767px)': {
        display: 'none',
      },
    },
  },
  { name: 'WMPublication' }
);

// @connect(
//   (state) => ({
//     publications: state.publications.publication,
//     error: state.publications.error,
//     loading: state.publications.loading,
//   }),
//   { getPublicationR: getPublication }
// )
const Publication = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { current: componentRef } = useRef({ forceVisible: false, to: null });
  const params = useParams();
  const location = useLocation();
  const intl = useIntl();
  const { publications, loading, error } = useSelector((state) => {
    return {
      publications: (state as any).publications.publication,
      error: (state as any).publications.error,
      loading: (state as any).publications.loading,
    };
  });

  const loadStream = (nextPage: any, to: any) => {
    setTimeout(() => {
      dispatch(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        getPublication(params.publication, nextPage, to, `${params.publication}-public`, {
          includeSections: true,
        })
      );
    }, 50);
  };

  const renderGrid = (section: any, publication: any) => {
    const rows = [];
    const { stories, storyInfoLayout, gridMargin, showTitle, title, uid } = section;
    const numberOfStories = stories.length;
    let rowToIterate = numberOfStories;
    let spacing = 3;
    const contentOverImg = storyInfoLayout === 'over image';

    const baseDate = new Date();

    if (contentOverImg) {
      const isPrime = rowToIterate % 2 !== 0;
      spacing = 2;
      spacing = gridMargin === 'n' ? 0 : spacing;
      if (isPrime) {
        rowToIterate -= 1;
        rows.push(
          <Grid key={`grid-row-section-${uid}-content-over-first`} item xs={12} sm={12}>
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <PublicationGrid
              imageLink={getLink(publication, stories[0])}
              titleLink={getLink(publication, stories[0])}
              image={getImage(stories[0].featureImg, 'max_2000')}
              spacing={spacing}
              contentOverImg={contentOverImg}
              bigImg
              extraBigImg
              author={stories[0].User}
              date={stories[0].publishDate}
              baseDate={baseDate}
              subtitle={stories[0].subtitle || 'No description in story'}
              title={stories[0].title || 'Untitle story'}
            />
          </Grid>
        );
      }

      let lastTypeSmallCount = 1;
      let i = numberOfStories - rowToIterate;
      for (; i < numberOfStories; i += 1) {
        const rowSmall = lastTypeSmallCount < 2;
        if (lastTypeSmallCount > 2) {
          lastTypeSmallCount = 0;
        } else {
          lastTypeSmallCount += 1;
        }

        rows.push(
          <Grid
            key={`grid-row-section-${uid}-content-over-${i}`}
            item
            xs={12}
            sm={rowSmall ? 5 : 7}
          >
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <PublicationGrid
              imageLink={getLink(publication, stories[i])}
              titleLink={getLink(publication, stories[i])}
              image={getImage(stories[i].featureImg, 'max_2000')}
              spacing={spacing}
              author={stories[i].User}
              date={stories[i].publishDate}
              baseDate={baseDate}
              contentOverImg={contentOverImg}
              title={stories[i].title || 'Untitle story'}
              subtitle={stories[i].subtitle || 'No description in story'}
            />
          </Grid>
        );
      }
    } else {
      spacing = gridMargin === 'n' ? 0 : spacing;

      if (spacing === 0) {
        const isPrime = numberOfStories % 2 !== 0;
        if (isPrime) {
          rowToIterate -= 1;
          rows.push(
            <Grid key={`grid-row-section-${uid}-content-under-nomargin-first`} item xs={12} sm={12}>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <PublicationGrid
                imageLink={getLink(publication, stories[0])}
                titleLink={getLink(publication, stories[0])}
                image={getImage(stories[0].featureImg, 'max_2000')}
                spacing={spacing}
                bigImg
                author={stories[0].User}
                date={stories[0].publishDate}
                baseDate={baseDate}
                subtitle={stories[0].subtitle || 'No description in story'}
                title={stories[0].title || 'Untitle story'}
              />
            </Grid>
          );
        }

        let lastTypeSmallCount = 1;
        let i = numberOfStories - rowToIterate;
        for (; i < numberOfStories; i += 1) {
          const rowSmall = lastTypeSmallCount < 2;
          if (lastTypeSmallCount > 2) {
            lastTypeSmallCount = 0;
          } else {
            lastTypeSmallCount += 1;
          }

          rows.push(
            <Grid
              key={`grid-row-section-${uid}-content-under-nomargin-${i}`}
              item
              xs={12}
              sm={rowSmall ? 5 : 7}
            >
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <PublicationGrid
                imageLink={getLink(publication, stories[i])}
                titleLink={getLink(publication, stories[i])}
                image={getImage(stories[i].featureImg, 'max_2000')}
                spacing={spacing}
                bigImg
                author={stories[i].User}
                date={stories[i].publishDate}
                baseDate={baseDate}
                subtitle={stories[i].subtitle || 'No description in story'}
                title={stories[i].title || 'Untitle story'}
              />
            </Grid>
          );
        }
      } else {
        const noBig = numberOfStories % 3 === 0;
        const twoBigs = !noBig && numberOfStories % 3 === 2;
        if (!twoBigs && !noBig) {
          rowToIterate -= 1;
          rows.push(
            <Grid key={`grid-row-section-${uid}-first`} item xs={12} sm={12}>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <PublicationGrid
                imageLink={getLink(publication, stories[0])}
                titleLink={getLink(publication, stories[0])}
                image={getImage(stories[0].featureImg, 'max_2000')}
                spacing={spacing}
                bigImg
                author={stories[0].User}
                date={stories[0].publishDate}
                baseDate={baseDate}
                subtitle={stories[0].subtitle || 'No description in story'}
                title={stories[0].title || 'Untitle story'}
              />
            </Grid>
          );
        } else if (twoBigs) {
          rowToIterate -= 2;
          rows.push(
            <Grid key={`grid-row-section-${uid}-first-two`} item xs={12} sm={6}>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <PublicationGrid
                imageLink={getLink(publication, stories[0])}
                titleLink={getLink(publication, stories[0])}
                image={getImage(stories[0].featureImg, 'max_2000')}
                spacing={spacing}
                mediumImg
                author={stories[0].User}
                date={stories[0].publishDate}
                baseDate={baseDate}
                subtitle={stories[0].subtitle || 'No description in story'}
                title={stories[0].title || 'Untitle story'}
              />
            </Grid>
          );
          rows.push(
            <Grid key={`grid-row-section-${uid}-second-two`} item xs={12} sm={6}>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <PublicationGrid
                imageLink={getLink(publication, stories[1])}
                titleLink={getLink(publication, stories[1])}
                image={getImage(stories[1].featureImg, 'max_2000')}
                spacing={spacing}
                mediumImg
                author={stories[1].User}
                date={stories[1].publishDate}
                baseDate={baseDate}
                subtitle={stories[1].subtitle || 'No description in story'}
                title={stories[1].title || 'Untitle story'}
              />
            </Grid>
          );
        }

        let i = numberOfStories - rowToIterate;
        for (; i < numberOfStories; i += 1) {
          rows.push(
            <Grid key={`grid-section-${uid}-story-${i}`} item xs={12} sm={4}>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <PublicationGrid
                imageLink={getLink(publication, stories[i])}
                titleLink={getLink(publication, stories[i])}
                image={getImage(stories[i].featureImg, 'max_2000')}
                spacing={spacing}
                author={stories[i].User}
                date={stories[i].publishDate}
                baseDate={baseDate}
                subtitle={stories[i].subtitle || 'No description in story'}
                title={stories[i].title || 'Untitle story'}
              />
            </Grid>
          );
        }
      }
    }

    return (
      <div
        key={`section-${uid}`}
        className={classNames({
          [classes.containerContentOverImg]: spacing !== 0 && contentOverImg,
        })}
      >
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'padding' is missing in type '{ children:... Remove this comment to see the full error message */}
        <Container
          component="section"
          size="publicationSize"
          fullWidth={spacing === 0}
          spacing={spacing}
          className={classes.containerGrid}
        >
          <Grid container spacing={spacing}>
            {showTitle === 'y' && !!title && (
              <Grid component="header" item xs={12}>
                <Typography className={classes.sectionTitle} variant="h3">
                  {title}
                </Typography>
              </Grid>
            )}
            {rows}
          </Grid>
        </Container>
      </div>
    );
  };

  const renderStream = (section: any, publication: any) => {
    const { stories, layout, showTitle, title: titleSection, uid } = section;
    const numberOfStories = stories.length;
    const rowToIterate = numberOfStories;
    const withImage = layout === 'list';

    const rows = [];

    const baseDate = new Date();

    for (let i = 0; i < rowToIterate; i += 1) {
      const story = stories[i];
      const { title, subtitle, User, publishDate } = story;
      rows.push(
        <Grid key={`grid-row-${layout}-section-${uid}`} item xs={12}>
          <PublicationStream
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            image={getImage(stories[i].featureImg, 'fit_c_280x240')}
            withImage={withImage}
            isLast={rowToIterate === i + 1}
            title={title}
            subtitle={subtitle}
            author={User}
            date={publishDate}
            baseDate={baseDate}
            imageLink={getLink(publication, story)}
            titleLink={getLink(publication, story)}
          />
        </Grid>
      );
    }
    const spacing = 5;
    return (
      // @ts-expect-error ts-migrate(2739) FIXME: Type '{ children: Element; component: "section"; k... Remove this comment to see the full error message
      <Container
        component="section"
        key={`publicationStream-section-${uid}`}
        size="publicationSize"
        spacing={spacing}
      >
        <Grid container spacing={spacing}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={4}>
              {showTitle === 'y' && !!titleSection && (
                <Grid component="header" item xs={12}>
                  <Typography className={classes.sectionTitle} variant="h3">
                    {titleSection}
                  </Typography>
                </Grid>
              )}
              {rows}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PublicationAbout publication={publication} />
              </Grid>
              <Grid item xs={12}>
                <Adsense
                  key={`${location.key}-adsense-publications-sidebar`}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; responsive: string; client: s... Remove this comment to see the full error message
                  responsive="true"
                  client="ca-pub-9169771209644523"
                  slot="8212156070"
                  format="vertical"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const renderMixed = (section: any, publication: any) => {
    const { stories } = section;
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <PublicationMixed
        key={`publicationMixed-${section.uid}`}
        publication={publication}
        stories={stories}
      />
    );
  };

  const renderSection = (section: any, publication: any) => {
    if (section.type === 'promo') {
      const {
        promoTitleValue,
        promoBackgroundColor,
        promoTitleActive,
        promoImageActive,
        promoTextActive,
        promoTextValue,
        promoButtonActive,
        promoButtonValue,
        promoImage,
        promoUrl,
      } = section;
      const backgrounPromo =
        promoBackgroundColor &&
        (promoBackgroundColor.length === 3 || promoBackgroundColor.length === 6)
          ? {
              backgroundColor: `#${promoBackgroundColor}`,
            }
          : {};

      let buttonAttribs = {};
      if (promoUrl) {
        buttonAttribs =
          promoUrl && String(promoUrl).indexOf('https://webmediums.com') !== -1
            ? {
                component: Link,
                to: promoUrl,
              }
            : {
                component: 'a',
                href: promoUrl,
                rel: 'noopener nofollow',
              };
      }

      const contrastThreshold = 3;
      const backgroundContrast =
        promoBackgroundColor &&
        getContrastRatio(`#${promoBackgroundColor}`, '#000') <= contrastThreshold
          ? { fill: '#fff', color: '#fff', borderColor: '#fff' }
          : {};

      return (
        // @ts-expect-error ts-migrate(2739) FIXME: Type '{ children: Element; size: "publicationSize"... Remove this comment to see the full error message
        <Container size="publicationSize">
          <Grid container>
            {promoTitleActive && !!promoTitleValue && (
              <Grid component="header" item xs={12}>
                <Typography
                  className={`${classes.sectionTitle} ${classes.sectiontitlePromo}`}
                  variant="h3"
                >
                  {promoTitleValue}
                </Typography>
              </Grid>
            )}
            <Grid style={backgrounPromo} item xs={12} className={classes.promoContainer}>
              <Grid
                direction="column"
                alignItems="center"
                container
                spacing={3}
                alignContent="center"
              >
                {promoImageActive && promoImage && promoImage.md5 && (
                  <Grid item xs className={classes.promoImageContainer}>
                    <img
                      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | false' is not assignable to type 's... Remove this comment to see the full error message
                      src={getImage(promoImage, 'max_560')}
                      alt={promoTextValue || promoButtonValue}
                    />
                  </Grid>
                )}
                {promoTextActive && (
                  <Grid item xs className={classes.promoTextContainer}>
                    <Typography
                      style={backgroundContrast}
                      className={classes.promoTitle}
                      variant="h1"
                      component="div"
                    >
                      {promoTextValue}
                    </Typography>
                  </Grid>
                )}
                {promoButtonActive && (
                  <Grid item xs className={classes.promoTextContainer}>
                    <Button style={backgroundContrast} variant="outlined" {...buttonAttribs}>
                      {promoButtonValue}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      );
    }
    const layouts = {
      grid: renderGrid,
      stream: renderStream,
      list: renderStream,
      mixed: renderMixed,
    };

    if (!section.stories || section.stories.length === 0) {
      return null;
    }

    return (
      <>
        {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
        {layouts[section.layout](section, publication)}
        <div className={classes.adsenseInnerSections}>
          <Adsense
            key={`${location.key}-${section.uid}-adsense-publications-innersections`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; responsive: string; client: s... Remove this comment to see the full error message
            responsive="true"
            client="ca-pub-9169771209644523"
            slot="4049549788"
            format="auto"
          />
        </div>
      </>
    );
  };

  const publicationStream = publications[`${params.publication}-public`];
  if (
    !loading &&
    (error || !publicationStream || !publicationStream[0] || !publicationStream[0].name)
  ) {
    return <NotFound error={error} />;
  }

  if (
    loading &&
    (error || !publicationStream || !publicationStream[0] || !publicationStream[0].name)
  ) {
    return 'Loading...';
  }
  const lastStream = publicationStream[publicationStream.length - 1];
  const publication = publicationStream[0];

  const hasMoreStories = lastStream && lastStream.pendginLoadMore;
  const { nextPage /* , to */ } = lastStream;
  // if (to) {
  //   this.to = to;
  // }
  const title = `${publication.name} – WebMediums`;
  const canonicalLink = `/${publication.slug}`;
  const { description } = publication;
  const featureImageSrc =
    publication.logo && publication.logo.md5 && getImage(publication.logo, 'max_1200');
  const canonicaFeddlLink = `${domain}/feed/${publication.slug}`;
  const helmet = (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <link rel="canonical" href={canonicalLink} />
      <meta name="description" content={description} />

      {featureImageSrc && <meta property="og:image" content={featureImageSrc} />}
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />
      <meta property="og:url" content={canonicalLink} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      {featureImageSrc && <meta name="twitter:image:src" content={featureImageSrc} />}

      {/* <link rel="author" href={canonicalLinkUser} /> */}
      {/* <meta property="author" content={publication ? publication.name : authorName} /> */}
      {/* <meta property="og:type" content="article" /> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="robots" content="index, follow" />
      <link rel="alternate" type="application/rss+xml" title="RSS" href={canonicaFeddlLink} />
    </Helmet>
  );
  return !publication.haveStories ? (
    // @ts-expect-error ts-migrate(2746) FIXME: This JSX tag's 'children' prop expects a single ch... Remove this comment to see the full error message
    <Container>
      {helmet}
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={12}>
          <div className={classes.noStoriesContainer}>
            <Typography variant="body1" align="center">
              This publication does not have any stories yet.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <>
      {helmet}
      {publicationStream.map((p: any) =>
        p.sections
          .slice(p.startPage, p.startPage + p.nextPage)
          .map(
            (section: any) => !section.pendingLoadingStories && renderSection(section, publication)
          )
      )}
      {hasMoreStories && !loading && (
        <VisibilitySensor partialVisibility offset={{ bottom: -500 }}>
          {({ isVisible, sensorRef }) => {
            if (isVisible) {
              componentRef.forceVisible = true;
              setTimeout(() => {
                componentRef.forceVisible = false;
              }, 500);
              loadStream(nextPage, componentRef.to);
            }
            return (
              <div ref={sensorRef} key="placeholder" className={classes.containerPlaceholder}>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                {(isVisible || loading || componentRef.forceVisible) && <PlaceholderLoading />}
              </div>
            );
          }}
        </VisibilitySensor>
      )}
      {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ children: Element; size: "publicationSize"... Remove this comment to see the full error message */}
      <Container size="publicationSize">
        <Grid container className={classes.containerFooter}>
          <Grid item xs={12} className={classes.linkSet}>
            <Button
              className={classes.noPadding}
              component={Link}
              to={`/${publication.slug}/about`}
            >
              About {publication.name}
            </Button>
            <div className={classes.dotHidden}>
              <MiddotDivider />
            </div>
            <Button
              className={classes.noPadding}
              component={Link}
              to={`/${publication.slug}/latest`}
            >
              Latest Stories
            </Button>
            <div className={classes.dotHidden}>
              <MiddotDivider />
            </div>
            <Button
              className={classes.noPadding}
              component={Link}
              to={`/${publication.slug}/archive`}
            >
              Archive
            </Button>
            <div className={classes.dotHidden}>
              <MiddotDivider />
            </div>
            <Button
              className={classes.noPadding}
              component={Link}
              to="/policy/politica-de-privacidad-de-webmediums-nkgyas2objom"
            >
              {intl.formatMessage({
                id: 'terms',
                defaultMessage: 'Terms',
              })}
            </Button>
            <div className={classes.dotHidden}>
              <MiddotDivider />
            </div>
            <Button
              className={classes.noPadding}
              component={Link}
              to="/policy/politica-de-privacidad-de-webmediums-nkgyas2objom"
            >
              {intl.formatMessage({
                id: 'privacy',
                defaultMessage: 'Privacy',
              })}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Publication;
