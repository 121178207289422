import { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link } from 'react-router-dom';
import getImageLink from 'utils/getImageLink';
import classNames from 'clsx';
import dateFormat from 'date-fns/format';

const styles = {
  titlePlaceHolder: {
    paddingTop: 5,
    paddingBottom: 2,
    margin: 0,
    fontSize: 26,
    fontWeight: 600,
    lineHeight: 1,
    wordBreak: 'break-word',
    wordWrap: 'break-word',
  },
  subtitle: {
    fontSize: 20,
    lineHeight: 1.3,
    marginTop: 7,
  },
  borderBottom: {
    paddingTop: 32,
    borderBottom: '1px solid rgba(0,0,0,.05)',
    clear: 'both',
  },
  linkImage: {
    border: '1px solid rgba(0,0,0,.15)',
    // background: '#f0f0f0',
    float: 'right',
    width: 140,
    height: 120,
    backgroundSize: 'cover',
    backgroundOrigin: 'border-box',
    marginLeft: 30,
    backgroundPosition: '50% 50%',
  },
  containerAvatar: {
    marginTop: 20,
  },
  containerPostMeta: {
    paddingLeft: 10,
    fontSize: 16,
    lineHeight: 1.4,
  },
  '@media screen and (max-width: 767px)': {
    linkImage: {
      width: 80,
      height: 80,
    },
    subtitle: {
      fontSize: 18,
    },
    titlePlaceHolder: {
      fontSize: 20,
    },
  },
};

type OwnProps = {
  classes: {
    [key: string]: any;
  };
  author: {
    [key: string]: any;
  };
  title?: string;
  subtitle?: string;
  image?: string | boolean;
  date: string | any;
  baseDate: string | any;
  imageLink: string;
  titleLink: string;
  isLast?: boolean;
  withImage?: boolean;
};

const defaultProps = {
  image: false,
  title: 'Untitle story',
  subtitle: 'Unsubtitle story',
  isLast: false,
  withImage: false,
};

type Props = OwnProps & typeof defaultProps;

// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withStyles(styles, { name: 'WMPublicationsStream' })
export default class PublicationStream extends Component<Props> {
  static defaultProps = defaultProps;

  render() {
    const {
      classes,
      title,
      subtitle,
      isLast,
      withImage,
      image,
      imageLink,
      titleLink,
      author,
      date,
      baseDate,
    } = this.props;

    const styleBg = image ? { backgroundImage: `url("${image}")` } : {};

    const { avatar, username, name } = author;

    const authorName = name || username;

    const ObjDate = new Date(date);
    const dateFullYear = ObjDate.getUTCFullYear();
    const baseDateFullYear = baseDate.getUTCFullYear();

    return (
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={12}>
          {withImage && (
            <Link aria-label={title} style={styleBg} to={imageLink} className={classes.linkImage} />
          )}
          <Link to={titleLink}>
            <Typography className={classNames(classes.titlePlaceHolder)} variant="h3">
              {title}
            </Typography>
            <Typography component="div" variant="caption" className={classes.subtitle}>
              {subtitle}
            </Typography>
          </Link>
          <div className={classNames(classes.containerAvatar, 'clearfix')}>
            <Grid container>
              <Grid item xs="auto">
                <Link to={`/@${username}`}>
                  {avatar && avatar.md5 ? (
                    <Avatar
                      alt={authorName}
                      src={getImageLink(avatar.md5, 'fit_c_72x72', avatar.format)}
                    />
                  ) : (
                    <Avatar alt={authorName}>
                      <AvatarIcon />
                    </Avatar>
                  )}
                </Link>
              </Grid>
              <Grid item xs="auto" className={classes.containerPostMeta}>
                <Link to={`/@${username}`}>
                  <Typography variant="caption">{authorName}</Typography>
                </Link>
                <Typography component="div" variant="caption">
                  <time dateTime={date}>
                    {dateFormat(
                      ObjDate,
                      dateFullYear === baseDateFullYear ? 'MMM d' : 'MMM d, yyyy'
                    )}
                  </time>
                </Typography>
              </Grid>
            </Grid>
          </div>

          {!isLast && <div className={classes.borderBottom} />}
        </Grid>
      </Grid>
    );
  }
}
