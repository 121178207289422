import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import classNames from 'clsx';
import { Link } from 'react-router-dom';
import { getImage } from 'utils/getImageLink';

const styles = {
  title: {
    color: 'rgba(0,0,0,.54)',
    fill: 'rgba(0,0,0,.54)',
    fontSize: 18,
    display: 'inline-block',
    paddingLeft: 10,
  },
  avatar: {
    width: 36,
    height: 36,
    borderRadius: 3,
    '-webkit-border-radius': '3px',
    display: 'inline-block',
  },
  containerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    color: 'rgba(0,0,0,.68)',
    fontSize: 16,
    marginBottom: 15,
  },
  linkPrimary: {
    color: '#1C9963',
    fontSize: 16,
  },
  header: {
    color: 'rgba(0,0,0,.54)',
    fontSize: 15,
    letterSpacing: '.1em',
    textTransform: 'uppercase',
  },
  followerCountText: {
    fontSize: 16,
    color: 'rgba(0,0,0,.68)',
  },
};

type Props = {
  classes: {
    [key: string]: any;
  };
  publication: {
    [key: string]: any;
  };
};

const PublicationAbout = (props: Props) => {
  const { classes, publication } = props;

  const avatarSrc = getImage(publication.avatar, 'fit_c_72x72');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.containerTitle}>
          <Link to={`/${publication.slug}`}>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | boolean' is not assignable to type ... Remove this comment to see the full error message */}
            <img className={classes.avatar} src={avatarSrc} alt={publication.name} />
          </Link>
          <Typography className={classNames(classes.title)}>{publication.name}</Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.description}>{publication.description}</Typography>
        <Link className={classes.linkPrimary} to={`/${publication.slug}/about`}>
          More information
        </Link>
      </Grid>
      <Grid item xs={12}>
        <header className={classes.header}>Followers</header>
        <div className={classes.followerCountText}>{publication.followersCount}</div>
      </Grid>
    </Grid>
  );
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ title: { color: string; fill: ... Remove this comment to see the full error message
export default withStyles(styles, { name: 'WMPublicationsAbout' })(PublicationAbout);
