import { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import AvatarIcon from '@mui/icons-material/AccountCircleOutlined';
import getImageLink from 'utils/getImageLink';
import classNames from 'clsx';
import dateFormat from 'date-fns/format';

const styles = {
  imgPlaceHolder: {
    display: 'flex',
    alignItems: 'flex-end',
    // border: '1px solid rgba(0,0,0,.15)',
    // background: '#f0f0f0',
    width: '100%',
    height: 172,
  },
  containerOverImage: {
    position: 'relative',
    '@media screen and (max-width: 767px)': {},
  },
  linkImage: {
    border: '1px solid rgba(0,0,0,.15)',
    backgroundColor: '#f0f0f0',
    width: '100%',
    backgroundSize: 'cover',
    backgroundOrigin: 'border-box',
    backgroundPosition: '50% 50%',
    height: 172,
  },
  imgPlaceHolderBig: {
    height: 350,
  },
  imgPlaceHolderMedium: {
    height: 272,
  },
  imgPlaceHolderExtraBig: {
    height: 450,
  },
  imageBgBrightness: {
    filter: 'brightness(40%)',
  },
  contentPlaceHolder: {
    background: 'rgba(0,0,0,.05)',
    height: 15,
    display: 'block',
    marginTop: 10,
  },
  contentPlaceHolderFirst: {
    width: '60%',
  },
  contentPlaceHolderSecond: {
    width: '20%',
  },
  title: {
    fontSize: 26,
    fontWeight: 600,
    lineHeight: 1.1,
    letterSpacing: 0,
    '--x-height-multiplier': 0.342,
    '--baseline-multiplier': 0.22,
    paddingTop: 5,
    paddingBottom: 2,
    wordBreak: 'break-word',
    wordWrap: 'break-word',
  },
  subtitle: {
    fontSize: 20,
    lineHeight: 1.2,
    marginTop: 7,
  },
  fontSize38: {
    fontSize: 38,
  },
  contentOverImg: {
    margin: 40,
    position: 'absolute',
    bottom: 0,
    '& $title, $subtitle': {
      color: 'rgba(255, 255, 255, 1)',
    },
    '@media screen and (max-width: 767px)': {
      margin: 20,
      marginTop: 15,
      marginLeft: 26,
    },
  },
  titleContainerNoPaddingPadding: {
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 20,
    paddingBottom: 30,
  },
  containerAvatar: {
    marginTop: 20,
  },
  containerPostMeta: {
    paddingLeft: 10,
    fontSize: 16,
    lineHeight: 1.4,
  },
  containerPostMetaUserDefaultLink: {
    color: '#1C9963',
  },
  containerPostMetaUser: {
    color: 'rgba(255, 255, 255, 1)',
    fill: 'rgba(255, 255, 255, 1)',
    fontSize: 16,
    lineHeight: 1.4,
  },
  containerPostMetaDate: {
    color: 'rgba(255, 255, 255, 0.8)',
    fill: 'rgba(255, 255, 255, 0.8)',
  },
  '@media screen and (max-width: 767px)': {
    fontSize38: {
      fontSize: 26,
    },

    imgPlaceHolder: {
      height: 170,
    },
    imgPlaceHolderMedium: {
      height: 170,
    },

    imgPlaceHolderBig: {
      height: 250,
    },
    imgPlaceHolderExtraBig: {
      height: 450,
    },
    subtitle: {},
    title: {},
    containerOverImage: {
      '& $subtitle, $title': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
      },
      '& $title': {
        maxHeight: 65,
      },
      '& $subtitle': {
        maxHeight: 50,
      },
    },
  },
};

type OwnProps = {
  classes: {
    [key: string]: any;
  };
  author: {
    [key: string]: any;
  };
  image: string | boolean;
  date: string | any;
  baseDate: string | any;
  bigImg?: boolean;
  extraBigImg?: boolean;
  title: string;
  titleLink: string;
  imageLink: string;
  subtitle?: string;
  mediumImg?: boolean;
  contentOverImg?: boolean;
  spacing?: number;
};
const defaultProps = {
  subtitle: 'Unsubtitle',
  bigImg: false,
  extraBigImg: false,
  mediumImg: false,
  contentOverImg: false,
  spacing: 5,
};
type Props = OwnProps & typeof defaultProps;

// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withStyles(styles, { name: 'WMPublicationsGrid' })
export default class PublicationGrid extends Component<Props> {
  static defaultProps = defaultProps;

  render() {
    const {
      classes,
      bigImg,
      mediumImg,
      title,
      subtitle,
      contentOverImg,
      spacing,
      image,
      titleLink,
      imageLink,
      author,
      date,
      baseDate,
      extraBigImg,
    } = this.props;

    let colsImg = 12;
    let colsContent = 12;
    if (bigImg && !contentOverImg && spacing !== 0) {
      colsImg = 8;
      colsContent = 4;
    } else if (mediumImg) {
      colsImg = 12;
      colsContent = 12;
    }

    const { avatar, username, name } = author;

    const authorName = name || username;

    const contentComponent = (
      <Grid
        item
        xs={12}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        sm={colsContent}
        className={classNames({
          [classes.titleContainerNoPaddingPadding]: !contentOverImg && spacing === 0,
        })}
      >
        <Link to={titleLink}>
          <Typography
            className={classNames(classes.title, { [classes.fontSize38]: bigImg })}
            variant="h3"
          >
            {title}
          </Typography>
          <Typography component="div" variant="caption" className={classes.subtitle}>
            {subtitle}
          </Typography>
        </Link>
        <div className={classNames(classes.containerAvatar, 'clearfix')}>
          <Grid container alignItems="center">
            <Grid item xs="auto">
              <Link to={`/@${username}`}>
                {avatar && avatar.md5 ? (
                  <Avatar
                    alt={authorName}
                    src={getImageLink(avatar.md5, 'fit_c_72x72', avatar.format)}
                  />
                ) : (
                  <Avatar alt={authorName}>
                    <AvatarIcon />
                  </Avatar>
                )}
              </Link>
            </Grid>
            <Grid item xs="auto" className={classes.containerPostMeta}>
              <Link to={`/@${username}`}>
                <Typography
                  className={classNames(classes.containerPostMetaUserDefaultLink, {
                    [classes.containerPostMetaUser]: contentOverImg,
                  })}
                  component="div"
                  variant="caption"
                >
                  {authorName}
                </Typography>
              </Link>
              <Typography
                component="div"
                className={classNames({ [classes.containerPostMetaDate]: contentOverImg })}
                variant="caption"
              >
                <time dateTime={date}>
                  {dateFormat(
                    new Date(date),
                    new Date(date).getUTCFullYear() === baseDate.getUTCFullYear()
                      ? 'MMM d'
                      : 'MMM d, yyyy'
                  )}
                </time>
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
    );

    const styleBg = image ? { backgroundImage: `url("${image}")` } : {};
    const spacingContainer = bigImg ? spacing : 1;

    const bigClass = extraBigImg ? classes.imgPlaceHolderExtraBig : classes.imgPlaceHolderBig;

    return (
      <Grid container spacing={contentOverImg ? 0 : spacingContainer}>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Grid item xs={12} sm={colsImg}>
          <div
            className={classNames(classes.imgPlaceHolder, {
              [bigClass]: bigImg || contentOverImg,
              [classes.imgPlaceHolderMedium]: mediumImg,
            })}
          >
            <Link
              aria-label={title}
              className={classNames(classes.linkImage, {
                [bigClass]: bigImg || contentOverImg,
                [classes.imgPlaceHolderMedium]: mediumImg,
                [classes.imageBgBrightness]: contentOverImg,
              })}
              style={styleBg}
              to={imageLink}
            />
          </div>
          {!!contentOverImg && (
            <div className={classes.containerOverImage}>
              <div className={classes.contentOverImg}>
                <Grid container>{contentComponent}</Grid>
              </div>
            </div>
          )}
        </Grid>
        {!contentOverImg && contentComponent}
      </Grid>
    );
  }
}
