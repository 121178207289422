import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import AvatarIcon from '@mui/icons-material/AccountCircleOutlined';
import classNames from 'clsx';
import getImageLink from 'utils/getImageLink';
import dateFormat from 'date-fns/format';
import { getLinkPublication as getLink } from 'utils/getLink';

const getImage = (image: any, version: any) => {
  if (image && image.md5) return getImageLink(image.md5, version, image.format);
  return false;
};

const useStyles = makeStyles(
  (theme) => ({
    container: {
      marginBottom: 5,
    },
    contentPlaceHolder: {
      background: 'rgba(0,0,0,.05)',
      height: 15,
      display: 'block',
      marginTop: 10,
    },
    contentPlaceHolderFirst: {
      width: '60%',
    },
    contentPlaceHolderSecond: {
      width: '20%',
    },
    title: {
      padding: '0!important',
      margin: 0,
      fontSize: 26,
    },
    fontSize38: {
      fontSize: 38,
    },
    containerImage: {
      minHeight: 440,
      position: 'relative',
    },
    containerDivImage: {
      position: 'relative',
      height: '100%',
    },
    imagePlaceHolder: {
      display: 'flex',
      alignItems: 'flex-end',
      background: '#f0f0f0',
      width: '100%',
      minHeight: 440,
    },
    linkImage: {
      background: '#f0f0f0',
      backgroundSize: 'cover',
      backgroundOrigin: 'border-box',
      backgroundPosition: '50% 50%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      filter: 'brightness(40%)',
    },
    titleOverImg: {
      marginRight: 40,
      marginLeft: 56,
      marginTop: 30,
      marginBottom: 46,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: '20%',
    },
    titleSecond: {
      lineHeight: 1.1,
      fontSize: 20,
      fontWeight: 600,
      maxHeight: '2.2em',
      overflow: 'hidden',
      paddingTop: 4,
      paddingBottom: 2,
    },
    titleOverImage: {
      color: 'rgb(255, 255, 255)',
      fontSize: 34,
      lineHeight: 1,
      fontWeight: 600,
    },
    subtitle: {
      fontSize: 20,
      lineHeight: 1.3,
      marginTop: 7,
      color: 'rgba(255, 255, 255, 0.9490196078431372)',
    },
    containerAvatar: {
      marginTop: 20,
    },
    containerPostMeta: {
      fontSize: 16,
      lineHeight: 1.4,
      paddingLeft: 10,
    },
    containerPostMetaUser: {
      color: 'rgba(255, 255, 255, 1)',
      fill: 'rgba(255, 255, 255, 1)',
    },
    containerPostMetaDate: {
      color: 'rgba(255, 255, 255, 0.8)',
      fill: 'rgba(255, 255, 255, 0.8)',
    },
    containerRight: {
      paddingBottom: 15,
    },
    containerRows: {
      paddingTop: 25,
      paddingRight: 20,
      maxWidth: 300,
    },
    rowwAuthorLink: {
      color: '#1C9963',
      fontSize: 16,
    },
    [theme.breakpoints.only('xs')]: {
      containerRows: {
        marginTop: -7,
        paddingTop: 0,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        maxWidth: 700,
      },
      titleOverImage: {
        fontSize: 26,
      },
      titleOverImg: {
        marginRight: 20,
        marginLeft: 54,
      },
    },
  }),
  { name: 'WMPublicationsMixed' }
);

type PublicationMixedProps = {
  stories: any[];
  publication: any[];
};

const PublicationMixed = (props: PublicationMixedProps) => {
  const classes = useStyles();
  const { stories, publication } = props;

  const firstStory = stories[0];
  const {
    title: firsttitle,
    subtitle: firstSubtitle,
    User: firstAuthor,
    publishDate: firstPublishDate,
  } = firstStory;
  const { avatar: firstAvatar, username: firstUsername, name: firstName } = firstAuthor;

  const firstAuthorName = firstName || firstUsername;

  const rowToIterate = stories.length;

  const rows = [];

  const baseDate = new Date();

  for (let i = 1; i < rowToIterate; i += 1) {
    const story = stories[i];
    const { title, User: author } = story;
    const { username, name } = author;
    const authorName = name || username;
    rows.push(
      <div key={`mixedRows-${story.uid}`} className={classes.containerRight}>
        <Typography className={classNames(classes.titleSecond)} variant="h3">
          <Link to={getLink(publication, story)}>{title}</Link>
        </Typography>

        <Link to={`/@${username}`} className={classes.rowwAuthorLink}>
          {authorName}
        </Link>
      </div>
    );
  }

  const image = getImage(stories[0].featureImg, 'max_2000');
  const styleBg = image ? { backgroundImage: `url("${image}")` } : {};
  const linkFirst = getLink(publication, firstStory);
  return (
    <Grid component="section" container spacing={4} className={classes.container}>
      <Grid item xs={12} sm={8} className={classes.containerImage}>
        <div className={classes.containerDivImage}>
          <Link
            aria-label={firsttitle}
            style={styleBg}
            to={linkFirst}
            className={classes.linkImage}
          />
        </div>
        <div className={classes.titleOverImg}>
          <Link to={linkFirst}>
            <Typography className={classNames(classes.titleOverImage)} variant="h3">
              {firsttitle}
            </Typography>
            <Typography component="div" variant="caption" className={classes.subtitle}>
              {firstSubtitle}
            </Typography>
          </Link>
          <div className={classNames(classes.containerAvatar, 'clearfix')}>
            <Grid container>
              <Grid item xs="auto">
                <Link to={`/@${firstUsername}`}>
                  {firstAvatar && firstAvatar.md5 ? (
                    <Avatar
                      alt={firstAuthorName}
                      src={getImageLink(firstAvatar.md5, 'fit_c_72x72', firstAvatar.format)}
                    />
                  ) : (
                    <Avatar alt={firstAuthorName}>
                      <AvatarIcon />
                    </Avatar>
                  )}
                </Link>
              </Grid>
              <Grid item xs="auto" className={classes.containerPostMeta}>
                <Link to={`/@${firstUsername}`}>
                  <Typography
                    variant="caption"
                    color="inherit"
                    className={classes.containerPostMetaUser}
                  >
                    {firstAuthorName}
                  </Typography>
                </Link>
                <Typography
                  component="div"
                  variant="caption"
                  color="inherit"
                  className={classes.containerPostMetaDate}
                >
                  <time dateTime={firstPublishDate}>
                    {dateFormat(
                      new Date(firstPublishDate),
                      new Date(firstPublishDate).getUTCFullYear() === baseDate.getUTCFullYear()
                        ? 'MMM d'
                        : 'MMM d, yyyy'
                    )}
                  </time>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className={classes.containerRows}>{rows}</div>
      </Grid>
    </Grid>
  );
};
export default PublicationMixed;
